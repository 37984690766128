import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormGrid,
    IBatchForm,
    IParams,
    isConflictException,
    Page,
    RemoveModal,
    useQueryParams,
} from '../../../shared';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBatchSchema } from '../../validators';
import { Button, MenuItem } from '@mui/material';
import { CodeType } from '../../../admin/enums';
import { batchFromFormMapper, batchToFormMapper } from '../../mappers';
import { BatchWarning } from '../../components';
import { useBatch, useDeleteBatch, useSaveBatch } from '../../hooks';
import { useQrCodesList, useSuppliersList } from '../../../admin/hooks';

export const BatchesEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const {} = useQueryParams();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const { data: batch, isFetching } = useBatch(id);
    const { mutateAsync: saveBatch, isPending: isPendingSave } = useSaveBatch();
    const { mutateAsync: deleteBatch } = useDeleteBatch();
    const { data: suppliers, isFetching: isFetchingSuppliers } = useSuppliersList({ pageSize: 1000 });
    const { data: qrCodes, isFetching: isFetchingQrCodes } = useQrCodesList({
        type: CodeType.BATCH,
        pageSize: 1000,
    });

    const form = useForm<IBatchForm>({
        resolver: yupResolver(useBatchSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (batch) {
            form.reset(batchToFormMapper(batch));
        }
    }, [batch, form]);

    const onSubmit = useCallback(
        async (item: IBatchForm, confirm = false) => {
            try {
                await saveBatch({ id, item: batchFromFormMapper(item, confirm) });
                navigate('/stock/batches');
            } catch (err) {
                if (isConflictException(err)) {
                    return setShowConfirm(true);
                }
                throw err;
            }
        },
        [id, navigate, saveBatch],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((item) => onSubmit(item))}
                disabled={isPendingSave}
            >
                {t('save')}
            </Button>,
            <>
                {batch && (
                    <RemoveModal
                        handleDelete={() => {
                            deleteBatch(batch.id);
                            navigate('/stock/batches');
                        }}
                        button={
                            <Button color="secondary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('batchDeleteWarningTitle')}
                        text={t('batchDeleteWarningText')}
                    />
                )}
            </>,
            <Button onClick={() => navigate('/stock/batches')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, isPendingSave, t, batch, onSubmit, deleteBatch, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={batch?.id ? t('editBatch') : t('newBatch')}
            actions={reversedActions}
            onBack={() => navigate(-1)}
            loading={isFetching || isPendingSave || isFetchingSuppliers || isFetchingQrCodes}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit((item) => onSubmit(item))} actions={actions}>
                    <FormGrid mt={1}>
                        <ControlledDatePicker name="date" label={t('date')} required />
                        <ControlledSelect name="supplierId" label={t('supplier')} required>
                            {suppliers?.data.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                        <ControlledInput name="referenceNumber" label={t('referenceNumber')} />
                        <ControlledSelect name="qrCodeId" label={t('qrCode')}>
                            <MenuItem value="" sx={{ p: 2 }} />
                            {qrCodes?.data.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    </FormGrid>
                </FormCard>
            </FormProvider>

            <BatchWarning
                show={showConfirm}
                onSubmit={form.handleSubmit((data) => onSubmit(data, true))}
                onCancel={() => setShowConfirm(false)}
            />
        </Page>
    );
};
